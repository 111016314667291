/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../app/API.service";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPay = /* GraphQL */ `query GetPay($id: ID!) {
  getPay(id: $id) {
    id
    amount
    typeChange
    balance
    date
    voucherURL
    ShippingType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    PayType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    sellID
    updatedBy
    createdBy
    isDollar
    createdAt
    updatedAt
    payShippingTypeId
    payPayTypeId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPayQueryVariables, APITypes.GetPayQuery>;
export const listPays = /* GraphQL */ `query ListPays($filter: ModelPayFilterInput, $limit: Int, $nextToken: String) {
  listPays(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      typeChange
      balance
      date
      voucherURL
      ShippingType {
        id
        name
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      PayType {
        id
        name
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      sellID
      updatedBy
      createdBy
      isDollar
      createdAt
      updatedAt
      payShippingTypeId
      payPayTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPaysQueryVariables, APITypes.ListPaysQuery>;
export const paysBySellID = /* GraphQL */ `query PaysBySellID(
  $sellID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPayFilterInput
  $limit: Int
  $nextToken: String
) {
  paysBySellID(
    sellID: $sellID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      typeChange
      balance
      date
      voucherURL
      ShippingType {
        id
        name
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      PayType {
        id
        name
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      sellID
      updatedBy
      createdBy
      isDollar
      createdAt
      updatedAt
      payShippingTypeId
      payPayTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaysBySellIDQueryVariables,
  APITypes.PaysBySellIDQuery
>;
export const getBillInfo = /* GraphQL */ `query GetBillInfo($id: ID!) {
  getBillInfo(id: $id) {
    id
    name
    address
    identification
    email
    createdBy
    updatedBy
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBillInfoQueryVariables,
  APITypes.GetBillInfoQuery
>;
export const listBillInfos = /* GraphQL */ `query ListBillInfos(
  $filter: ModelBillInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listBillInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBillInfosQueryVariables,
  APITypes.ListBillInfosQuery
>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    message
    createdBy
    updatedBy
    quoteID
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    date
    createdAt
    updatedAt
    commentUserId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      createdBy
      updatedBy
      quoteID
      User {
        id
        name
        email
        phoneNumber
        imgURL
        isAdmin
        gender
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        identification
        identificationType
        createdBy
        updatedBy
        BillInfo {
          id
          name
          address
          identification
          email
          createdBy
          updatedBy
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        userSystemCodeId
        userBillInfoId
        __typename
      }
      date
      createdAt
      updatedAt
      commentUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const commentsByQuoteID = /* GraphQL */ `query CommentsByQuoteID(
  $quoteID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByQuoteID(
    quoteID: $quoteID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      createdBy
      updatedBy
      quoteID
      User {
        id
        name
        email
        phoneNumber
        imgURL
        isAdmin
        gender
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        identification
        identificationType
        createdBy
        updatedBy
        BillInfo {
          id
          name
          address
          identification
          email
          createdBy
          updatedBy
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        userSystemCodeId
        userBillInfoId
        __typename
      }
      date
      createdAt
      updatedAt
      commentUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByQuoteIDQueryVariables,
  APITypes.CommentsByQuoteIDQuery
>;
export const getSetting = /* GraphQL */ `query GetSetting($id: ID!) {
  getSetting(id: $id) {
    id
    imgs
    gain
    minimumCost
    purchaseCost
    weightRate
    createdBy
    updatedBy
    deliveryCost
    internTax
    feeWeight
    feeWeightClient
    iva
    dollar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSettingQueryVariables,
  APITypes.GetSettingQuery
>;
export const listSettings = /* GraphQL */ `query ListSettings(
  $filter: ModelSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      imgs
      gain
      minimumCost
      purchaseCost
      weightRate
      createdBy
      updatedBy
      deliveryCost
      internTax
      feeWeight
      feeWeightClient
      iva
      dollar
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSettingsQueryVariables,
  APITypes.ListSettingsQuery
>;
export const getFeedback = /* GraphQL */ `query GetFeedback($id: ID!) {
  getFeedback(id: $id) {
    id
    message
    stars
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeedbackQueryVariables,
  APITypes.GetFeedbackQuery
>;
export const listFeedbacks = /* GraphQL */ `query ListFeedbacks(
  $filter: ModelFeedbackFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      stars
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeedbacksQueryVariables,
  APITypes.ListFeedbacksQuery
>;
export const getSellDetail = /* GraphQL */ `query GetSellDetail($id: ID!) {
  getSellDetail(id: $id) {
    id
    sellID
    Product {
      id
      name
      link
      linkLocation
      cost
      quantity
      weight
      imgURL
      height
      width
      details
      guideWEB
      WeightType {
        id
        name
        abbreviation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      ProductType {
        id
        name
        createdBy
        updatedBy
        Tax {
          id
          amount
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        productTypeTaxId
        productTypeSystemCodeId
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      quoteID
      price
      createdBy
      updatedBy
      ProductStatus {
        id
        name
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      surcharge
      discount
      createdAt
      updatedAt
      productWeightTypeId
      productProductTypeId
      productSystemCodeId
      productProductStatusId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    sellDetailProductId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSellDetailQueryVariables,
  APITypes.GetSellDetailQuery
>;
export const listSellDetails = /* GraphQL */ `query ListSellDetails(
  $filter: ModelSellDetailFilterInput
  $limit: Int
  $nextToken: String
) {
  listSellDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sellID
      Product {
        id
        name
        link
        linkLocation
        cost
        quantity
        weight
        imgURL
        height
        width
        details
        guideWEB
        WeightType {
          id
          name
          abbreviation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        ProductType {
          id
          name
          createdBy
          updatedBy
          Tax {
            id
            amount
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          productTypeTaxId
          productTypeSystemCodeId
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        quoteID
        price
        createdBy
        updatedBy
        ProductStatus {
          id
          name
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        surcharge
        discount
        createdAt
        updatedAt
        productWeightTypeId
        productProductTypeId
        productSystemCodeId
        productProductStatusId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      sellDetailProductId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSellDetailsQueryVariables,
  APITypes.ListSellDetailsQuery
>;
export const sellDetailsBySellID = /* GraphQL */ `query SellDetailsBySellID(
  $sellID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSellDetailFilterInput
  $limit: Int
  $nextToken: String
) {
  sellDetailsBySellID(
    sellID: $sellID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sellID
      Product {
        id
        name
        link
        linkLocation
        cost
        quantity
        weight
        imgURL
        height
        width
        details
        guideWEB
        WeightType {
          id
          name
          abbreviation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        ProductType {
          id
          name
          createdBy
          updatedBy
          Tax {
            id
            amount
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          productTypeTaxId
          productTypeSystemCodeId
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        quoteID
        price
        createdBy
        updatedBy
        ProductStatus {
          id
          name
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        surcharge
        discount
        createdAt
        updatedAt
        productWeightTypeId
        productProductTypeId
        productSystemCodeId
        productProductStatusId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      sellDetailProductId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SellDetailsBySellIDQueryVariables,
  APITypes.SellDetailsBySellIDQuery
>;
export const getSell = /* GraphQL */ `query GetSell($id: ID!) {
  getSell(id: $id) {
    id
    date
    amount
    createadBy
    updatedBy
    SellDetails {
      items {
        id
        sellID
        Product {
          id
          name
          link
          linkLocation
          cost
          quantity
          weight
          imgURL
          height
          width
          details
          guideWEB
          WeightType {
            id
            name
            abbreviation
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          ProductType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            productTypeTaxId
            productTypeSystemCodeId
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          quoteID
          price
          createdBy
          updatedBy
          ProductStatus {
            id
            name
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          surcharge
          discount
          createdAt
          updatedAt
          productWeightTypeId
          productProductTypeId
          productSystemCodeId
          productProductStatusId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        sellDetailProductId
        __typename
      }
      nextToken
      __typename
    }
    discount
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    balance
    Pays {
      items {
        id
        amount
        typeChange
        balance
        date
        voucherURL
        ShippingType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        PayType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        sellID
        updatedBy
        createdBy
        isDollar
        createdAt
        updatedAt
        payShippingTypeId
        payPayTypeId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    sellBillInfoId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSellQueryVariables, APITypes.GetSellQuery>;
export const listSells = /* GraphQL */ `query ListSells(
  $filter: ModelSellFilterInput
  $limit: Int
  $nextToken: String
) {
  listSells(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSellsQueryVariables, APITypes.ListSellsQuery>;
export const getPayType = /* GraphQL */ `query GetPayType($id: ID!) {
  getPayType(id: $id) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPayTypeQueryVariables,
  APITypes.GetPayTypeQuery
>;
export const listPayTypes = /* GraphQL */ `query ListPayTypes(
  $filter: ModelPayTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPayTypesQueryVariables,
  APITypes.ListPayTypesQuery
>;
export const getShippingType = /* GraphQL */ `query GetShippingType($id: ID!) {
  getShippingType(id: $id) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShippingTypeQueryVariables,
  APITypes.GetShippingTypeQuery
>;
export const listShippingTypes = /* GraphQL */ `query ListShippingTypes(
  $filter: ModelShippingTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listShippingTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShippingTypesQueryVariables,
  APITypes.ListShippingTypesQuery
>;
export const getAddress = /* GraphQL */ `query GetAddress($id: ID!) {
  getAddress(id: $id) {
    id
    placeName
    province
    canton
    district
    codePostal
    reciverName
    reciverLastName
    isDefault
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    addressUserId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAddressQueryVariables,
  APITypes.GetAddressQuery
>;
export const listAddresses = /* GraphQL */ `query ListAddresses(
  $filter: ModelAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      placeName
      province
      canton
      district
      codePostal
      reciverName
      reciverLastName
      isDefault
      User {
        id
        name
        email
        phoneNumber
        imgURL
        isAdmin
        gender
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        identification
        identificationType
        createdBy
        updatedBy
        BillInfo {
          id
          name
          address
          identification
          email
          createdBy
          updatedBy
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        userSystemCodeId
        userBillInfoId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      addressUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAddressesQueryVariables,
  APITypes.ListAddressesQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    name
    link
    linkLocation
    cost
    quantity
    weight
    imgURL
    height
    width
    details
    guideWEB
    WeightType {
      id
      name
      abbreviation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    ProductType {
      id
      name
      createdBy
      updatedBy
      Tax {
        id
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productTypeTaxId
      productTypeSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    quoteID
    price
    createdBy
    updatedBy
    ProductStatus {
      id
      name
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    surcharge
    discount
    createdAt
    updatedAt
    productWeightTypeId
    productProductTypeId
    productSystemCodeId
    productProductStatusId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      link
      linkLocation
      cost
      quantity
      weight
      imgURL
      height
      width
      details
      guideWEB
      WeightType {
        id
        name
        abbreviation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      ProductType {
        id
        name
        createdBy
        updatedBy
        Tax {
          id
          amount
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        productTypeTaxId
        productTypeSystemCodeId
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      quoteID
      price
      createdBy
      updatedBy
      ProductStatus {
        id
        name
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      surcharge
      discount
      createdAt
      updatedAt
      productWeightTypeId
      productProductTypeId
      productSystemCodeId
      productProductStatusId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const productsByQuoteID = /* GraphQL */ `query ProductsByQuoteID(
  $quoteID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productsByQuoteID(
    quoteID: $quoteID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      link
      linkLocation
      cost
      quantity
      weight
      imgURL
      height
      width
      details
      guideWEB
      WeightType {
        id
        name
        abbreviation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      ProductType {
        id
        name
        createdBy
        updatedBy
        Tax {
          id
          amount
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        productTypeTaxId
        productTypeSystemCodeId
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      quoteID
      price
      createdBy
      updatedBy
      ProductStatus {
        id
        name
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      surcharge
      discount
      createdAt
      updatedAt
      productWeightTypeId
      productProductTypeId
      productSystemCodeId
      productProductStatusId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductsByQuoteIDQueryVariables,
  APITypes.ProductsByQuoteIDQuery
>;
export const getQuote = /* GraphQL */ `query GetQuote($id: ID!) {
  getQuote(id: $id) {
    id
    comment
    date
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    QuoteStatus {
      id
      name
      order
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      ActionByQuoteStatuses {
        items {
          id
          ActionQuoteStatus {
            id
            name
            description
            updatedBy
            createdBy
            createdAt
            updatedAt
            actionQuoteStatusSystemCodeId
            __typename
          }
          quotestatusID
          createdBy
          updatedBy
          createdAt
          updatedAt
          actionByQuoteStatusActionQuoteStatusId
          __typename
        }
        nextToken
        __typename
      }
      templateEmail
      isSaveTypeChange
      createdAt
      updatedAt
      quoteStatusSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdBy
    updatedBy
    Products {
      items {
        id
        name
        link
        linkLocation
        cost
        quantity
        weight
        imgURL
        height
        width
        details
        guideWEB
        WeightType {
          id
          name
          abbreviation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        ProductType {
          id
          name
          createdBy
          updatedBy
          Tax {
            id
            amount
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          productTypeTaxId
          productTypeSystemCodeId
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        quoteID
        price
        createdBy
        updatedBy
        ProductStatus {
          id
          name
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        surcharge
        discount
        createdAt
        updatedAt
        productWeightTypeId
        productProductTypeId
        productSystemCodeId
        productProductStatusId
        __typename
      }
      nextToken
      __typename
    }
    Sell {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    SellShipping {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    Feedback {
      id
      message
      stars
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    isPayTotal
    Comments {
      items {
        id
        message
        createdBy
        updatedBy
        quoteID
        User {
          id
          name
          email
          phoneNumber
          imgURL
          isAdmin
          gender
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          identification
          identificationType
          createdBy
          updatedBy
          BillInfo {
            id
            name
            address
            identification
            email
            createdBy
            updatedBy
            phoneNumber
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          userSystemCodeId
          userBillInfoId
          __typename
        }
        date
        createdAt
        updatedAt
        commentUserId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    quoteUserId
    quoteQuoteStatusId
    quoteSystemCodeId
    quoteSellId
    quoteSellShippingId
    quoteFeedbackId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetQuoteQueryVariables, APITypes.GetQuoteQuery>;
export const listQuotes = /* GraphQL */ `query ListQuotes(
  $filter: ModelQuoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      comment
      date
      User {
        id
        name
        email
        phoneNumber
        imgURL
        isAdmin
        gender
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        identification
        identificationType
        createdBy
        updatedBy
        BillInfo {
          id
          name
          address
          identification
          email
          createdBy
          updatedBy
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        userSystemCodeId
        userBillInfoId
        __typename
      }
      QuoteStatus {
        id
        name
        order
        updatedBy
        createdBy
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        ActionByQuoteStatuses {
          items {
            id
            quotestatusID
            createdBy
            updatedBy
            createdAt
            updatedAt
            actionByQuoteStatusActionQuoteStatusId
            __typename
          }
          nextToken
          __typename
        }
        templateEmail
        isSaveTypeChange
        createdAt
        updatedAt
        quoteStatusSystemCodeId
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      updatedBy
      Products {
        items {
          id
          name
          link
          linkLocation
          cost
          quantity
          weight
          imgURL
          height
          width
          details
          guideWEB
          WeightType {
            id
            name
            abbreviation
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          ProductType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            productTypeTaxId
            productTypeSystemCodeId
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          quoteID
          price
          createdBy
          updatedBy
          ProductStatus {
            id
            name
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          surcharge
          discount
          createdAt
          updatedAt
          productWeightTypeId
          productProductTypeId
          productSystemCodeId
          productProductStatusId
          __typename
        }
        nextToken
        __typename
      }
      Sell {
        id
        date
        amount
        createadBy
        updatedBy
        SellDetails {
          items {
            id
            sellID
            createdBy
            updatedBy
            createdAt
            updatedAt
            sellDetailProductId
            __typename
          }
          nextToken
          __typename
        }
        discount
        BillInfo {
          id
          name
          address
          identification
          email
          createdBy
          updatedBy
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        balance
        Pays {
          items {
            id
            amount
            typeChange
            balance
            date
            voucherURL
            sellID
            updatedBy
            createdBy
            isDollar
            createdAt
            updatedAt
            payShippingTypeId
            payPayTypeId
            __typename
          }
          nextToken
          __typename
        }
        typeChange
        createdAt
        updatedAt
        sellBillInfoId
        __typename
      }
      SellShipping {
        id
        date
        amount
        createadBy
        updatedBy
        SellDetails {
          items {
            id
            sellID
            createdBy
            updatedBy
            createdAt
            updatedAt
            sellDetailProductId
            __typename
          }
          nextToken
          __typename
        }
        discount
        BillInfo {
          id
          name
          address
          identification
          email
          createdBy
          updatedBy
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        balance
        Pays {
          items {
            id
            amount
            typeChange
            balance
            date
            voucherURL
            sellID
            updatedBy
            createdBy
            isDollar
            createdAt
            updatedAt
            payShippingTypeId
            payPayTypeId
            __typename
          }
          nextToken
          __typename
        }
        typeChange
        createdAt
        updatedAt
        sellBillInfoId
        __typename
      }
      Feedback {
        id
        message
        stars
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      isPayTotal
      Comments {
        items {
          id
          message
          createdBy
          updatedBy
          quoteID
          User {
            id
            name
            email
            phoneNumber
            imgURL
            isAdmin
            gender
            identification
            identificationType
            createdBy
            updatedBy
            createdAt
            updatedAt
            userSystemCodeId
            userBillInfoId
            __typename
          }
          date
          createdAt
          updatedAt
          commentUserId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      quoteUserId
      quoteQuoteStatusId
      quoteSystemCodeId
      quoteSellId
      quoteSellShippingId
      quoteFeedbackId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuotesQueryVariables,
  APITypes.ListQuotesQuery
>;
export const getActionByQuoteStatus = /* GraphQL */ `query GetActionByQuoteStatus($id: ID!) {
  getActionByQuoteStatus(id: $id) {
    id
    ActionQuoteStatus {
      id
      name
      description
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      actionQuoteStatusSystemCodeId
      __typename
    }
    quotestatusID
    createdBy
    updatedBy
    createdAt
    updatedAt
    actionByQuoteStatusActionQuoteStatusId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionByQuoteStatusQueryVariables,
  APITypes.GetActionByQuoteStatusQuery
>;
export const listActionByQuoteStatuses = /* GraphQL */ `query ListActionByQuoteStatuses(
  $filter: ModelActionByQuoteStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listActionByQuoteStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ActionQuoteStatus {
        id
        name
        description
        updatedBy
        createdBy
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        actionQuoteStatusSystemCodeId
        __typename
      }
      quotestatusID
      createdBy
      updatedBy
      createdAt
      updatedAt
      actionByQuoteStatusActionQuoteStatusId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionByQuoteStatusesQueryVariables,
  APITypes.ListActionByQuoteStatusesQuery
>;
export const actionByQuoteStatusesByQuotestatusID = /* GraphQL */ `query ActionByQuoteStatusesByQuotestatusID(
  $quotestatusID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelActionByQuoteStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  actionByQuoteStatusesByQuotestatusID(
    quotestatusID: $quotestatusID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ActionQuoteStatus {
        id
        name
        description
        updatedBy
        createdBy
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        actionQuoteStatusSystemCodeId
        __typename
      }
      quotestatusID
      createdBy
      updatedBy
      createdAt
      updatedAt
      actionByQuoteStatusActionQuoteStatusId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ActionByQuoteStatusesByQuotestatusIDQueryVariables,
  APITypes.ActionByQuoteStatusesByQuotestatusIDQuery
>;
export const getActionQuoteStatus = /* GraphQL */ `query GetActionQuoteStatus($id: ID!) {
  getActionQuoteStatus(id: $id) {
    id
    name
    description
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    actionQuoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionQuoteStatusQueryVariables,
  APITypes.GetActionQuoteStatusQuery
>;
export const listActionQuoteStatuses = /* GraphQL */ `query ListActionQuoteStatuses(
  $filter: ModelActionQuoteStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listActionQuoteStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      actionQuoteStatusSystemCodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionQuoteStatusesQueryVariables,
  APITypes.ListActionQuoteStatusesQuery
>;
export const getQuoteStatus = /* GraphQL */ `query GetQuoteStatus($id: ID!) {
  getQuoteStatus(id: $id) {
    id
    name
    order
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    ActionByQuoteStatuses {
      items {
        id
        ActionQuoteStatus {
          id
          name
          description
          updatedBy
          createdBy
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          actionQuoteStatusSystemCodeId
          __typename
        }
        quotestatusID
        createdBy
        updatedBy
        createdAt
        updatedAt
        actionByQuoteStatusActionQuoteStatusId
        __typename
      }
      nextToken
      __typename
    }
    templateEmail
    isSaveTypeChange
    createdAt
    updatedAt
    quoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuoteStatusQueryVariables,
  APITypes.GetQuoteStatusQuery
>;
export const listQuoteStatuses = /* GraphQL */ `query ListQuoteStatuses(
  $filter: ModelQuoteStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuoteStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      order
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      ActionByQuoteStatuses {
        items {
          id
          ActionQuoteStatus {
            id
            name
            description
            updatedBy
            createdBy
            createdAt
            updatedAt
            actionQuoteStatusSystemCodeId
            __typename
          }
          quotestatusID
          createdBy
          updatedBy
          createdAt
          updatedAt
          actionByQuoteStatusActionQuoteStatusId
          __typename
        }
        nextToken
        __typename
      }
      templateEmail
      isSaveTypeChange
      createdAt
      updatedAt
      quoteStatusSystemCodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuoteStatusesQueryVariables,
  APITypes.ListQuoteStatusesQuery
>;
export const getProductStatus = /* GraphQL */ `query GetProductStatus($id: ID!) {
  getProductStatus(id: $id) {
    id
    name
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductStatusQueryVariables,
  APITypes.GetProductStatusQuery
>;
export const listProductStatuses = /* GraphQL */ `query ListProductStatuses(
  $filter: ModelProductStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductStatusesQueryVariables,
  APITypes.ListProductStatusesQuery
>;
export const getStore = /* GraphQL */ `query GetStore($id: ID!) {
  getStore(id: $id) {
    id
    name
    link
    logo
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    storeSystemCodeId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStoreQueryVariables, APITypes.GetStoreQuery>;
export const listStores = /* GraphQL */ `query ListStores(
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      link
      logo
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      storeSystemCodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStoresQueryVariables,
  APITypes.ListStoresQuery
>;
export const getSystemCode = /* GraphQL */ `query GetSystemCode($id: ID!) {
  getSystemCode(id: $id) {
    id
    value
    entity
    field
    label
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemCodeQueryVariables,
  APITypes.GetSystemCodeQuery
>;
export const listSystemCodes = /* GraphQL */ `query ListSystemCodes(
  $filter: ModelSystemCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listSystemCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemCodesQueryVariables,
  APITypes.ListSystemCodesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    email
    phoneNumber
    imgURL
    isAdmin
    gender
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    identification
    identificationType
    createdBy
    updatedBy
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userSystemCodeId
    userBillInfoId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getProductType = /* GraphQL */ `query GetProductType($id: ID!) {
  getProductType(id: $id) {
    id
    name
    createdBy
    updatedBy
    Tax {
      id
      amount
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    productTypeTaxId
    productTypeSystemCodeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductTypeQueryVariables,
  APITypes.GetProductTypeQuery
>;
export const listProductTypes = /* GraphQL */ `query ListProductTypes(
  $filter: ModelProductTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdBy
      updatedBy
      Tax {
        id
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productTypeTaxId
      productTypeSystemCodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypesQueryVariables,
  APITypes.ListProductTypesQuery
>;
export const getWeightType = /* GraphQL */ `query GetWeightType($id: ID!) {
  getWeightType(id: $id) {
    id
    name
    abbreviation
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWeightTypeQueryVariables,
  APITypes.GetWeightTypeQuery
>;
export const listWeightTypes = /* GraphQL */ `query ListWeightTypes(
  $filter: ModelWeightTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listWeightTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      abbreviation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWeightTypesQueryVariables,
  APITypes.ListWeightTypesQuery
>;
export const getTax = /* GraphQL */ `query GetTax($id: ID!) {
  getTax(id: $id) {
    id
    amount
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTaxQueryVariables, APITypes.GetTaxQuery>;
export const listTaxes = /* GraphQL */ `query ListTaxes($filter: ModelTaxFilterInput, $limit: Int, $nextToken: String) {
  listTaxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTaxesQueryVariables, APITypes.ListTaxesQuery>;
